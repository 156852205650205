import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chapters-animate"
export default class extends Controller {
  static targets = ["banner", "chapterDescription"]

  connect() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    }, { threshold: 0.1 });

    this.observer.observe(this.bannerTarget);
    this.observer.observe(this.chapterDescriptionTarget);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
