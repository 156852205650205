import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {index:Number}
  static targets = ["dot","slide", "card"]
  static interval

  connect() {
    // console.log("Slideshow controller is connected")
    // console.log("cards", this.cardTargets)
    this.cardTargets.forEach((element, index) => {
      element.style.display = "none"
    })
  }

  setIndex(e){
    const index = e.currentTarget.dataset.id
    this.indexValue = index
  }

  nexte(){
    this.indexValue = this.indexValue == 10 ? 1 : this.indexValue + 1
  }

  next(){
    // console.log(this.cardTargets.length)
    // console.log("yep next", this.indexValue, this.cardTarget)
    this.indexValue = this.indexValue == this.cardTargets.length ? 1 : this.indexValue+1
  }

  previous(){
    // console.log("yep previous", this.indexValue)
    this.indexValue = this.indexValue == 1 ? this.cardTargets.length : this.indexValue-1
  }

  indexValueChanged(){
    for (let i = 0;i < this.slideTargets.length;i++){
      this.slideTargets.forEach((element, index) => {
        element.style.display = "none"
      this.setInterval()
      })
      this.slideTargets[this.indexValue - 1].style.display = "flex"
    }
    for (let n = 0;n < this.cardTargets.length;n++){
      this.cardTargets.forEach((element, index) => {
        element.style.display = "none"
      })
      this.cardTargets[this.indexValue - 1].style.display = "flex"
    }
  }

  setInterval(){
    clearInterval(this.interval);
    this.interval = setInterval(()=>{this.nexte()},10000);
  }

}
