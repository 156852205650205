import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["modal","closeBtn"]

  connect() {
    // console.log(window.visualViewport);
    // console.log('modal target', this.modalTarget.firstChild)
    // console.log("close target", this.closeBtnTarget)
    const ifrm = this.modalTarget.childNodes[3].children[2];
    // console.log(ifrm.src.indexOf("?"))
  }

  close(e) {
    // console.log(e);
    // console.log("eTarget", e.target);
    // console.log("modalTarget", this.modalTarget.parentNode)
    if (e.target == this.modalTarget || e.target == this.closeBtnTarget) {
      this.modalTarget.style.display = "none";
    }
  }

  open(e){
    // clearInterval(e)
    // console.log(this.modalTarget);
    this.modalTarget.style.display = "flex";
  }
}
